import React from "react"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"

const slugify = require("slugify")

const Blogs = ({ location, data }) => {
	return (
		<Layout location={location}>
			<section className="page">
				<div className="container text-white">
					<div className="content bg-black p-5">
						<div className="section-content">
							{data.allSanityPost.edges.map(blog => (
								<div className="row" key={data}>
									<div className="col-md-6">
										<div>
											<img
												style={{ maxWidth: `100%`, height: `auto` }}
												src={blog.node.mainImage.asset.fluid.src}
												alt={blog.node.title}
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="blog-wrapper">
											<h2>
												<Link
													to={`${slugify(
														blog.node.slug.current.toLowerCase()
													)}`}
												>
													{blog.node.title}
												</Link>
											</h2>
											<p>{blog.node.excerpt}</p>
											<small>{blog.node.publishedAt}</small>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</section>
		</Layout>
	)
}

export default Blogs

export const blogQuery = graphql`
	query {
		allSanityPost {
			edges {
				node {
					id
					title
					slug {
						current
					}
					excerpt
					publishedAt(formatString: "MMMM, DD YYYY")
					mainImage {
						asset {
							fluid {
								src
							}
						}
					}
				}
			}
		}
	}
`
